import Keycloak from 'keycloak-js';

/**
 * Ritorna i ms di durata del Token
 * @param keycloak
 */
export function getAccessTokenLifespanRemainingTime(keycloak: Keycloak) {
  const { timeSkew, tokenParsed } = keycloak;
  const _timeSkew = timeSkew || 0;

  console.log('getAccessTokenLifespanRemainingTime', Keycloak);

  if (tokenParsed && tokenParsed.exp) {
    return (
      Math.round(tokenParsed.exp + _timeSkew - new Date().getTime() / 1000) *
      1000
    );
  }

  // valore di default
  return 30000;
}

/**
 * Ritorna i ms di durata del Token
 * @param keycloak
 */
export function getAccessTokenLifespan(keycloak: Keycloak) {
  const { tokenParsed } = keycloak;

  if (tokenParsed && tokenParsed.iat && tokenParsed.exp) {
    return (tokenParsed.exp - tokenParsed.iat) * 1000;
  }
  return 30000;
}
