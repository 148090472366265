import * as React from 'react';
import {BrowserRouter} from 'react-router-dom';
import AppContent from './AppContent';
import {internazionalization} from '../utils/lang';
import StoreProvider from './StoreProvider';
import {AbilityProvider} from './Common/Ability/AbilityContext';
import {AuthProvider, useAuth} from '@nic/kc-auth';
import axiosinstance from "../const/axiosInstances";

const App: React.FC =() => {
    internazionalization();
    const [keycloakConfig, setKeycloakConfig] = React.useState<
        Keycloak.KeycloakConfig
    >({} as Keycloak.KeycloakConfig);
    React.useEffect(() => {
        fetch('/keycloak.json')
            .then(response => response.json())
            .then(data => {
                const conf = {
                    realm: data.realm,
                    url: data['auth-server-url'],
                    clientId: data.resource,
                };
                // console.log('App.useEffect kcConf', conf);
                setKeycloakConfig(conf);
            })
            .catch(error => console.error('Error fetching keycloak configuration data:', error));
    }, []);

    // console.log('INSPECTION ->', App.name)
    if (keycloakConfig.clientId && keycloakConfig.realm && keycloakConfig.url) {
        return (
            <AuthProvider keycloakConfig={keycloakConfig}>
                <InnerApp/>
            </AuthProvider>
        );
    } else {
        return <>Waiting conf</>;
    }
};

export default App;


const InnerApp: React.FC = () => {
    const {keycloak} = useAuth();
    // console.log('INSPECTION ->', App.name)
    if (keycloak) {
        axiosinstance(keycloak);

    }
    return (
        <BrowserRouter>
            <AbilityProvider>
                <StoreProvider>
                    <AppContent/>
                </StoreProvider>
            </AbilityProvider>
        </BrowserRouter>
    );
};
