import * as React from 'react';
import { DispatchContext, StateContext } from '../../../StoreProvider';
import { FetchController } from '../../../Common/Navigation/FetchController/FetchController';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { IContactEvents } from '../types/contactEvents';
import { fetchContactEvents } from '../operations';
import PaginatedTable from '../PaginatedTable';
import { useAuth } from '@nic/kc-auth';

const ContactEvents: React.FC<{ refRevisionDate?: string }> = ({
  refRevisionDate,
}) => {
  const dispatch = React.useContext(DispatchContext);
  const { contactEvents } = React.useContext(StateContext);
  const keycloak = useAuth();
  const location = useLocation();
  const { params } = useRouteMatch();

  React.useEffect(() => {
    fetchContactEvents(
      dispatch,
      contactEvents,
      params['contact'],
      keycloak,
      location,
    );
  }, [location.pathname]);

  return (
    <div>
      <FetchController {...contactEvents}>
        {(data: IContactEvents) => (
          <PaginatedTable {...data} refRevisionDate={refRevisionDate} />
        )}
      </FetchController>
    </div>
  );
};

export default ContactEvents;
