import * as React from "react";
import {Route, RouteComponentProps, RouteProps} from "react-router-dom";
import {Alert} from "antd";
import Keycloak  from "keycloak-js";
import {useAuth} from "@nic/kc-auth";

export interface IProtectedRouteProps extends RouteProps {
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    allowed: boolean;
}

//
// type RenderComponent = (keycloak: IKeycloak, props: RouteComponentProps<any>) => React.ReactNode;
//
// export class ProtectedRoute extends Route<IProtectedRouteProps> {
//
//   public render() {
//     const { component: Component, allowed = true, ...rest }: IProtectedRouteProps = this.props;
//
//     const renderComponent: RenderComponent = (keycloak, props) => {
//       return (keycloak.keycloak.authenticated && allowed) ?
//         <Component {...keycloak} {...props} /> : <NotAllowed />;
//     };
//
//     return (
//       <AuthConsumer>
//         {({ keycloak }) => {
//
//           return <Route {...rest} render={props => renderComponent({ keycloak }, props)} />;
//         }}
//       </AuthConsumer>
//     );
//   }
// }


export const ProtectedRoute: React.FC<IProtectedRouteProps> = ({component: Component, allowed, location, ...rest}) => {
    const {keycloak} = useAuth();
    console.log("ProtectedRoute",keycloak?.authenticated && allowed, keycloak?.authenticated ,allowed,keycloak);
    if (keycloak?.authenticated && allowed) {
        return <Route {...rest} render={props => PrivateRender(Component, keycloak, props, allowed)}/>;
    } else {
        return <Route {...rest} render={() => <NotAllowed/>}/>;
    }
};

type renderComponentType = (Component: any, keycloak: Keycloak, props: RouteComponentProps<any>, allowed: boolean) => React.ReactNode;

const PrivateRender: renderComponentType = (Component, keycloak, props, allowed) => {
    return (keycloak.authenticated && allowed) ?
        <Component keycloak={keycloak} {...props} /> : <NotAllowed/>;
};


const NotAllowed = () => <Alert
    message="Operazione negata"
    description="L'utente non dispone dei permessi sufficienti"
    type="info"
/>;


