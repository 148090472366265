import axios from 'axios';
import { getAccessTokenLifespanRemainingTime } from '../utils/token';
import {
  API_CONTACTS,
  API_DOCUMENTS,
  API_DOMAINS,
  API_DOMAINS_GOV,
  API_DOMAINS_RESERVED,
  API_EVENTS,
  API_NOTES,
  API_REGISTRARS,
  API_SECURITY,
  API_USERS,
} from '../midaConfig';
import Keycloak from 'keycloak-js';

export const axiosApiInstance = axios.create();
export const axiosApiInstanceDomains = axios.create();
export const axiosApiInstanceContacts = axios.create();
export const axiosApiInstanceDocuments = axios.create();
export const axiosApiInstanceNotes = axios.create();
export const axiosApiInstanceDomainsReserved = axios.create();
export const axiosApiInstanceRegistrars = axios.create();
export const axiosApiInstanceSecurity = axios.create();
export const axiosApiInstanceUsers = axios.create();
export const axiosApiInstanceEvents = axios.create();
export const axiosApiInstanceDomainsGov = axios.create();

// Istanza di axios
export default (kc: Keycloak) => {
  axiosApiInstance.interceptors.request.use(
    config => generalAxiosTokenHandler(config, kc),
    // (error) => {
    //   console.error("axiosInstance Error ", error);
    //   return Promise.reject(error);
    // }
  );

  axiosApiInstanceDomains.interceptors.request.use(config => {
    config.baseURL = API_DOMAINS;
    return generalAxiosTokenHandler(config, kc);
  });

  axiosApiInstanceDomainsGov.interceptors.request.use(config => {
    config.baseURL = API_DOMAINS_GOV;
    return generalAxiosTokenHandler(config, kc);
  });

  axiosApiInstanceEvents.interceptors.request.use(config => {
    config.baseURL = API_EVENTS;
    return generalAxiosTokenHandler(config, kc);
  });

  axiosApiInstanceContacts.interceptors.request.use(config => {
    config.baseURL = API_CONTACTS;
    return generalAxiosTokenHandler(config, kc);
  });

  axiosApiInstanceNotes.interceptors.request.use(config => {
    config.baseURL = API_NOTES;
    return generalAxiosTokenHandler(config, kc);
  });

  axiosApiInstanceDocuments.interceptors.request.use(config => {
    config.baseURL = API_DOCUMENTS;
    return generalAxiosTokenHandler(config, kc);
  });

  axiosApiInstanceDomainsReserved.interceptors.request.use(config => {
    config.baseURL = API_DOMAINS_RESERVED;
    return generalAxiosTokenHandler(config, kc);
  });

  axiosApiInstanceRegistrars.interceptors.request.use(config => {
    config.baseURL = API_REGISTRARS;
    return generalAxiosTokenHandler(config, kc);
  });

  axiosApiInstanceSecurity.interceptors.request.use(config => {
    config.baseURL = API_SECURITY;
    return generalAxiosTokenHandler(config, kc);
  });

  axiosApiInstanceUsers.interceptors.request.use(config => {
    config.baseURL = API_USERS;
    return generalAxiosTokenHandler(config, kc);
  });

  // FUNZIONANTE, PRIMA VERSIONE
  // // Request interceptor for API calls
  //   axiosApiInstance.interceptors.request.use(
  //
  //     async config => {
  //       console.log("ohhhhh ");
  //
  //       config.headers = {
  //         "Authorization": `Bearer ${kc.token}`,
  //         "Accept": "application/json",
  //         "Content-Type": "application/x-www-form-urlencoded"
  //       };
  //       return config;
  //     },
  //     error => {
  //       Promise.reject(error);
  //     });

  // FUNZIONANTE SU RESPONE
  // Response interceptor for API calls
  //   axiosApiInstance.interceptors.response.use((response) => {
  //     return response;
  //   }, async (error) => {
  //     const originalRequest = error.config;
  //     if (error.response.status === 401 && !originalRequest._retry) {
  //       originalRequest._retry = true;
  //       console.log("response test ", error);
  //       kc.updateToken(5).success((refreshed: boolean) => {
  //         if (refreshed) {
  //           console.debug("Token was successfully refreshed, expire in " + getAccessTokenLifespanRemainingTime(kc) / 1000 + " seconds");
  //         } else {
  //           console.warn("Token not refreshed, valid for ", getAccessTokenLifespanRemainingTime(kc) / 1000 + " seconds");
  //           axiosApiInstance.defaults.headers.common["Authorization"] = "Bearer " + kc.token;
  //         }
  //       }).error(() => {
  //         kc.clearToken();
  //         console.error("Failed to refresh the token, or the session has expired");
  //       });
  //       return axios(originalRequest);
  //     }
  //     return Promise.reject(error);
  //   });
};

function generalAxiosTokenHandler(config: any, kc: Keycloak) {
  console.debug('Axios instance call [config]', config);
  // console.log(`${JSON.stringify(config, null, 2)}`);

  return refreshCall(kc)
    .then(() => {
      config.headers = {
        Authorization: `Bearer ${kc.token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...config.headers,
      };
      // @ts-ignore
      return Promise.resolve(config);
    })
    .catch((error: any) => {
      // decidi cosa fare se non riesce a prendere il token
      console.log('ee ', error);
      kc.logout();
      // @ts-ignore
      return Promise.reject(error);
    });
}

/***
 * Esegue il controllo e recupero del token attraverso le utility di keycloak
 * @param kc
 */
function refreshCall(kc: Keycloak): any {
  return new Promise<void>(async (resolve, reject) => {
    try {
      await kc.updateToken(5);
      console.debug(
        'Token was successfully refreshed, expire in ' +
          getAccessTokenLifespanRemainingTime(kc) / 1000 +
          ' seconds',
      );
      resolve();
    } catch (e) {
      reject(
        new Error('Failed to refresh the token, or the session has expired'),
      );
    }
  });
}
