import { PREFIX_DOMAINS_SERVICE } from '../midaConfig';
import { AxiosPromise, AxiosRequestConfig } from 'axios';
import { IRegistryEvent } from '../components/Domains/Events/types/domainEvents';
import { IFax, IProtocol } from '../components/Documents/documents';
import {
  axiosApiInstance,
  axiosApiInstanceContacts,
  axiosApiInstanceDocuments,
  axiosApiInstanceDomains,
  axiosApiInstanceDomainsGov,
  axiosApiInstanceDomainsReserved,
  axiosApiInstanceEvents,
  axiosApiInstanceNotes,
  axiosApiInstanceRegistrars,
  axiosApiInstanceSecurity,
  axiosApiInstanceUsers,
} from './axiosInstances';
import { IEditContactOperation } from '../components/Contacts/Detail/EditContact/Steps';
import Keycloak from 'keycloak-js';

/***
 * Chiamata base verso Domains Service API
 *
 * Si può utilzzare per chiamate da customizzare
 *
 * @param query
 * @param keycloak
 */
export function apiBaseDomainService(query: string) {
  const url = `${PREFIX_DOMAINS_SERVICE}${query}`;
  return axiosApiInstance.get(url);
}

/************
 *
 *   DOMAINS
 *
 *************/

/****
 * Dettaglio dominio
 *
 * @param domainId
 * @param keycloak
 */
export function apiGetDomainDetails(domainId: string) {
  const url = `${domainId}?view=all`;
  return axiosApiInstanceDomains.get(url);
}

/***
 * Ricerca  domini
 *
 * @param query
 * @param keycloak
 */
export function apiGetDomains(query: string) {
  // Per abilitare la ricerca per registrant scommneta questo comando
  // per ora viene tolto perchè lento
  const url = `?${query}&view=registrant&view=admin`;
  // const url = `${API_DOMAINS}?${query}`;
  return axiosApiInstanceDomains.get(url);
}

/***
 * Esporta una la ricerca  domini in formato CSV o JSON
 *
 * @param query
 * @param format
 * @param views
 * @param config
 */
export function apiGetDomainsStream(
  query: string,
  views: any[],
  format?: 'csv' | 'jsonlines',
  config?: AxiosRequestConfig,
) {
  const viewParams = `${views.length > 0 ? `&view=${views.join(',')}` : ''}`;
  console.log('viewParams ', viewParams);
  const url = `/stream?${query}${viewParams}&format=${format || 'csv'}`;
  return axiosApiInstanceDomains.get(url, {
    responseType: 'stream',
    timeout: 150000,
    ...config,
  });
}

/***
 * Ricerca  dominio tramite il nome
 *
 * @param query
 * @param keycloak
 */
export function apiGetDomainByName(query: string) {
  const url = `/findByName/${query}?view=all`;

  // const url = `${API_DOMAINS}?${query}`;
  return axiosApiInstanceDomains.get(url);
}

/***
 * Conteggio dei domini associati
 *
 * @param query
 * @param keycloak
 */
export function apiCountAssociatedDomains(query: string) {
  const url = `/count?${query}`;
  return axiosApiInstanceDomains.get(url);
}

/***
 * Dettaglio di uno specifico Domain Revision che mostra tramite vista tutti i dettagli (view=all)
 * @param domainId
 * @param revisionNumber
 * @param keycloak
 */
export function apiGetDomainRevision(domainId: string, revisionNumber: string) {
  const url = `/${domainId}/revisions/${revisionNumber}?view=all`;
  return axiosApiInstanceDomains.get(url);
}

/***
 * Dettaglio del Registrante di uno specifico Domain Revision
 * @param domainId
 * @param revisionNumber
 * @param keycloak
 */
export function apiGetRegistrantDomainRevision(
  domainId: string,
  revisionNumber: string,
) {
  const url = `/${domainId}/revisions/${revisionNumber}/registrant`;
  return axiosApiInstanceDomains.get(url);
}

/***
 * Dettaglio dell'Admin di uno specifico Domain Revision
 * @param domainId
 * @param revisionNumber
 * @param keycloak
 */
export function apiGetAdminDomainRevision(
  domainId: string,
  revisionNumber: string,
) {
  const url = `/${domainId}/revisions/${revisionNumber}/admin`;
  return axiosApiInstanceDomains.get(url);
}

/***
 * Dettaglio Domain Revision
 * @param domainId
 * @param keycloak
 */
export function apiGetDomainRevisions(domainId: string) {
  const DEFAULT_SIZE = 100000;
  const url = `/${domainId}/revisions?size=${DEFAULT_SIZE}`;
  return axiosApiInstanceDomains.get(url);
}

/***
 * Codice Authinfo del dominio
 * @param domainId
 * @param keycloak
 */
export function apiGetDomainAuthinfo(domainId: string) {
  const url = `/${domainId}/authInfo`;
  return axiosApiInstanceDomains.get(url);
}

/***
 * Codice Authinfo storico del dominio
 * @param domainId
 * @param keycloak
 */
export function apiGetDomainHistoryAuthinfo(
  domainId: string,
  revisionNumber: string,
) {
  const url = `/${domainId}/revisions/${revisionNumber}/authInfo`;
  return axiosApiInstanceDomains.get(url);
}

/***
 *
 * @param domainId
 * @param query
 * @param keycloak
 */
export function apiGetDomainEvents(domainId: string, query: string) {
  const url = `/${domainId}/events${query}`;
  return axiosApiInstanceDomains.get(url);
}

/***
 *
 * @param domainId
 * @param values
 * @param domainIfMatchValue
 */
export function apiPostDomainRegistryEvent(
  domainId: string,
  values: IRegistryEvent,
  domainVersion: number,
) {
  const url = `/${domainId}/events`;
  return axiosApiInstanceDomains.post(url, JSON.stringify(values), {
    headers: {
      'IF-Match': `"${domainVersion}"`,
    },
  });
}

/***
 *
 * @param domainId
 * @param keycloak
 */
export function apiGetDomainAvailableEvents(domainId: string) {
  const url = `/${domainId}/events/available`;
  return axiosApiInstanceDomains.get(url);
}

/***
 *
 */
export function apiGetEventsRequirements() {
  const url = `/requirements`;
  return axiosApiInstanceEvents.get(url);
}

/***
 * Recupera tutte le note di un dominio
 * @param domainId
 * @param query
 * @param keycloak
 */
export function apiGetDomainNotes(domainId: string, query: string) {
  const url = `/${domainId}/notes`;
  return axiosApiInstanceDomains.get(url);
}

/**
 * Aggiunge una nuova nota nel dominio
 * @param domainId
 * @param values
 * @param keycloak
 */
export function apiPostDomainNotes(domainId: string, values: object) {
  const url = `/${domainId}/notes`;
  return axiosApiInstanceDomains.post(url, JSON.stringify(values), {
    headers: {
      Accept: 'application/json, */*',
      'Content-Type': 'application/json',
    },
  });
}

/************
 *
 *   DOMAINS GOV
 *
 *************/

/****
 * Dettaglio amministrazione Gov
 *
 * @param ipaCode
 */
export function apiGetGovAdministration(ipaCode: string) {
  const url = `/amministrazioni/${ipaCode}`;
  return axiosApiInstanceDomainsGov.get(url);
}

/****
 * Dettaglio unità amministrativa Gov
 *
 * @param ipaCode
 * @param uoCode
 */
export function apiGetGovAdministrationByIpaAndUOCode(
  ipaCode: string,
  uoCode: string,
) {
  const url = `/unitaOrganizzative?codiceUO=${uoCode}`;
  return axiosApiInstanceDomainsGov.get(url);
}

/************
 *
 *   CONTACTS
 *
 *************/

/****
 * Dettaglio contatto
 *
 * @param contactId
 * @param keycloak
 */
export function apiGetContactDetails(contactId: string) {
  const url = `/${contactId}?view=linkedCounter,status`;
  return axiosApiInstanceContacts.get(url);
}

/****
 * Dettaglio contatto
 *
 * @param contactId
 * @param keycloak
 */
export function apiGetContactByContactId(contactId: string) {
  const url = `/findByContactId/${contactId}?view=linkedCounter,status`;
  return axiosApiInstanceContacts.get(url);
}

/***
 * Ricerca  contatti
 *
 * @param query
 * @param keycloak
 */
export function apiGetContacts(query: string) {
  const url = `?${query}&view=linkedCounter,status`;
  return axiosApiInstanceContacts.get(url);
}

/***
 * Esporta una la ricerca  dei contatti in formato CSV o JSON
 *
 * @param query
 * @param format
 * @param views
 * @param config
 */
export function apiGetContactsStream(
  query: string,
  views: any[],
  format?: 'csv' | 'jsonlines',
  config?: AxiosRequestConfig,
) {
  const viewParams = `${views.length > 0 ? `&view=${views.join(',')}` : ''}`;
  console.log('viewParams ', viewParams);
  const url = `/stream?${query}${viewParams}&format=${format || 'csv'}`;
  return axiosApiInstanceContacts.get(url, {
    responseType: 'stream',
    timeout: 150000, // mettere a 5' 30''
    ...config,
  });
}

/***
 * Dettaglio Contact Revision
 * @param contactId
 * @param revisionNumber
 * @param keycloak
 */
export function apiGetContactRevision(
  contactId: string,
  revisionNumber: string,
) {
  const url = `/${contactId}/revisions/${revisionNumber}?view=all`;
  return axiosApiInstanceContacts.get(url);
}

/***
 * Revisioni del Contact
 * @param contactId
 * @param keycloak
 */
export function apiGetContactRevisions(contactId: string) {
  const DEFAULT_SIZE = 100000;
  const url = `/${contactId}/revisions?size=${DEFAULT_SIZE}`;
  return axiosApiInstanceContacts.get(url);
}

/***
 *
 * @param contactId
 * @param query
 * @param keycloak
 */
export function apiGetContactEvents(
  contactId: string,
  query: string,
  keycloak: Keycloak,
) {
  const url = `/${contactId}/events${query}`;
  return axiosApiInstanceContacts.get(url);
}

export function apiPutContact(
  contactId: number,
  editOperation: IEditContactOperation,
) {
  if (editOperation && editOperation.etag && contactId) {
    let url: string;
    url = `/${editOperation &&
      editOperation.contact &&
      contactId}/registryUpdate`;
    return axiosApiInstanceContacts.put(url, editOperation, {
      headers: {
        'If-Match': editOperation.etag,
      },
    });
  } else {
    throw new Error(
      "Mancanza dei parametri richiesti per aggiornare il contatto 'etag' o 'contact' " +
        JSON.stringify(editOperation),
    );
  }
}

/************
 *
 *   SECURITY
 *
 *************/

/***
 * Codice Authinfo del dominio
 * @param values
 * @param keycloak
 */
export function apiGetDecryptedAuthinfo(values: {
  encodedPassword: string;
  keyTag: number;
}) {
  const url = `/authinfo/decrypt`;
  return axiosApiInstanceSecurity.post(
    url,
    JSON.stringify(values),
    // {
    // headers: {
    //   "Accept": "application/json, */*",
    //   "Content-Type": "application/json",
    //   Authorization: "Bearer " + keycloak.token
    // }
    // }
  );
}

/*
POST /security-service/authinfo/decrypt HTTP/1.1
Host: api.devel.nic.it

{
    "encodedPassword": "Z9fP5h5FQr/BQKyzT3at0eU+Ej4ldxF6g81gC6T5Le8",
    "keyTag": "1"
}
*/

/************
 *
 *   DOCUMENTS
 *
 *************/

/***
 * Ritorna i dettagli di un documento
 * @param documentId
 */
export function apiGetDocument(documentId: string) {
  const url = `/${documentId}`;
  return axiosApiInstanceDocuments.get(url);
}

/***
 * Ritorna i dettagli di un documento
 * @param _year
 * @param _number
 */
export function apiGetDocumentByYearAndNumber(
  _year: number,
  _number: number,
): AxiosPromise<IProtocol> {
  const url = `/protocols/findByYearAndNumber/${_year}/${_number}`;
  return axiosApiInstanceDocuments.get(url);
}

/**
 * Crea un fax
 * @param values
 * @param keycloak
 */

export function apiPostFax(values: IFax) {
  const url = `/fax`;
  return axiosApiInstanceDocuments.post(
    url,
    JSON.stringify(values),
    //   {
    //   headers: {
    //     "Accept": "application/json, */*",
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer " + keycloak.token
    //   }
    // }
  );
}

/***
 * Crea un protocollo
 * @param values
 * @param keycloak
 */
export function apiPostProtocol(values: IProtocol) {
  const url = `/protocols`;
  return axiosApiInstanceDocuments.post(
    url,
    JSON.stringify(values),
    //   {
    //   headers: {
    //     "Accept": "application/json, */*",
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer " + keycloak.token
    //   }
    // }
  );
}

/***
 * Lista stati e province
 * @param keycloak
 */
export function apiGetStateOrProvince(): AxiosPromise<any>[] {
  const urls = [`/countries`, `/province`];
  return urls.map(url => {
    return axiosApiInstanceDomainsReserved.get(url);
  });
}

/****
 * Dettaglio utente
 *
 * @param userId
 * @param keycloak
 */
export function apiGetUser(userId: number) {
  const url = `/${userId}`;
  return axiosApiInstanceUsers.get(url);
}

/************
 *
 *   REGISTRARS
 *
 *************/

/***
 * Ritorna il contratto del Registrar
 * @param registrarId
 * @param keycloak
 */
export function getRegistrarContract(registrarId: number) {
  const url = `/${registrarId}/contract`;
  return axiosApiInstanceRegistrars.get(url);
}

/***
 * Ritorna i contatti del Registrar
 * @param registrarId
 * @param keycloak
 */
export function getRegistrarContacts(registrarId: number) {
  const url = `/${registrarId}/contacts`;
  return axiosApiInstanceRegistrars.get(url);
}

/***
 * Ritorna i contatti emails del Registrar
 * @param registrarId
 * @param keycloak
 */
export function getRegistrarEmails(registrarId: number) {
  const url = `/${registrarId}/emails`;
  return axiosApiInstanceRegistrars.get(url);
}

/***
 * Ritorna i dati customer del Registrar
 * @param registrarId
 * @param keycloak
 */
export function getRegistrarCustomer(registrarId: number) {
  const url = `/${registrarId}/customer`;
  return axiosApiInstanceRegistrars.get(url);
}

/****
 * Dettaglio registrar
 *
 * @param registrarId
 * @param keycloak
 */
export function apiGetRegistrar(registrarId: number) {
  const url = `/${registrarId}`;
  return axiosApiInstanceRegistrars.get(url);
}

/************
 *
 *   NOTES
 *
 *************/

/**
 * Modifica una note esistente
 * @param noteId
 * @param values
 * @param keycloak
 */
export function apiPutDomainNote(noteId: number, values: object) {
  const url = `/${noteId}`;
  return axiosApiInstanceNotes.put(
    url,
    JSON.stringify(values),
    //   {
    //   headers: {
    //     "Accept": "application/json, */*",
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer " + keycloak.token
    //   }
    // }
  );
}

/**
 * Elimina una nota esistente
 * @param noteId
 * @param keycloak
 */
export function apiDeleteDomainNote(noteId: number) {
  const url = `/${noteId}`;
  return axiosApiInstanceNotes.delete(url);
}

/**
 *
 * Sostitusce momentaneamente apiGetDomainRevision
 *
 * NOTA:
 * questa è una patch in attesa che venga rilasciato nell'API il campo 'created' tramite l'endpoint 'apiGetDomainRevision' invocato in precedenza.
 * Con questa patch si provvede a scaricare il dettaglio storico del Registrante e dell'admin si appende all'oggetto target che è quello che viene poi restituito.
 *
 * Quando le API saranno corrette dovrebbe bastare invocare solamente il metodo apiGetDomainRevision.
 *
 * L'aggiunta eseguita per ottenere il created del registrante e admin rallenta la visualizzazione dovuto al fatto che sono ora 3 chiamate rispetto ad uno.
 *
 * @param domainId
 * @param revisionNumber
 * @param keycloak
 */
export async function apiPatchedGetDomainRevision(
  domainId: string,
  revisionNumber: string,
) {
  const target = await apiGetDomainRevision(domainId, revisionNumber);
  const registrant = await apiGetRegistrantDomainRevision(
    domainId,
    revisionNumber,
  );
  const admin = await apiGetAdminDomainRevision(domainId, revisionNumber);
  return {
    ...target,
    data: {
      ...target.data,
      // si recuperano  i dati del registrante e se non viene trovata la data di lastupdate
      // allora questa viene valorizzata con quella della revisione
      registrant:
        registrant.data.lastupdate !== undefined
          ? registrant.data
          : {
              ...registrant.data,
              lastupdate: target.data.revision.date,
            },
      admin: admin.data,
    },
  };
}

export async function apiPatchedGetContactRevision(
  contactId: string,
  revisionNumber: string,
) {
  const target = await apiGetContactRevision(contactId, revisionNumber);
  return {
    ...target,
    data: {
      ...target.data,
      // si recuperano  i dati del registrante e se non viene trovata la data di lastupdate
      // allora questa viene valorizzata con quella della revisione
      lastupdate:
        target.data.lastupdate !== undefined
          ? target.data.lastupdate
          : target.data.revision.date,
    },
  };
}
