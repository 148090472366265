import { Ability, AbilityBuilder, AbilityClass } from '@casl/ability';
import { map, reduceRight } from 'lodash';
import Keycloak, { KeycloakTokenParsed } from 'keycloak-js';

type Actions =
  | 'search'
  | 'read'
  | 'manage'
  | 'history'
  | 'create'
  | 'update'
  | 'delete'
  | 'delete-all'
  | 'registry-update';
type Subjects =
  | 'Authinfo'
  | 'Domain'
  | 'Event'
  | 'Contact'
  | 'Registrar'
  | 'Note'
  | 'all';

export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;

type DefinePermissions = (
  user: Keycloak,
  builder: AbilityBuilder<AppAbility>,
) => void;

/***
 * DOMINI:
 *  READ: DOMAIN-READ, USER
 *  SEARCH: DOMAIN-SEARCH, OPERATOR, TECH
 *  HISTORY: DOMAIN-HISTORY, OPERATOR
 *
 *
 *
 *  read : poter cercare tramite ricerca che produce  0 o 1 risultati
 *  search : poter cercare tramite ricerca (avanzata) che produce  0 o n risultati
 *
 *
 *
 * CONTATTI:
 *  READ: CONTACT-READ, USER
 *  SEARCH: CONTACT-SEARCH, OPERATOR, TECH
 *  HISTORY: CONTACT-HISTORY, OPERATOR, TECH
 *
 *
 *  EVENTI-DOMINI:
 *   READ: DOMAIN-EVENT-READ, USER
 *
 *  EVENTI DOMINI (OPERAZIONI DOMINI):
 *  (create) SERVER-LOCK, SERVER-UNLOCK: TECH-ADMIN, DOMAIN-EVENT-SERVER-LOCK, DOMAINS-VERIFY-SERVICE
 *  (create) REVOKE-STARTED: TECH-ADMIN, DOMAIN-EVENT- REVOKE-STARTED, OPERATOR
 *  (create) SERVER-HOLD, SERVER-UNHOLD: TECH-ADMIN, DOMAIN-EVENT- SERVER-HOLD, OPERATOR
 *  (create) DOMAIN-DELETED-BY-REGISTRY: TECH-ADMIN, DOMAIN-EVENT- DOMAIN-DELETED-BY-REGISTRY, OPERATOR
 *  (create) CHALLENGED-START, CHALLENGED-END TECH-ADMIN, DOMAIN-EVENT CHALLENGED, LEGAL
 *  (create) SERVER-DELETE-PROHIBITED: TECH-ADMIN, DOMAIN-EVENT- SERVER-DELETE-PROHIBITED, OPERATOR
 *
 *
 *
 *  NOTE:
 *      READ : DOMAIN-NOTE-READ, USER
 *      CREATE: DOMAIN-NOTE-CREATE, OPERATOR, LEGAL
 *      UPDATE: DOMAIN-NOTE-UPDATE, OPERATOR, LEGAL
 *      DELETE: DOMAIN-NOTE-DELETE, OPERATOR, LEGAL
 *      DELETE-ALL: DOMAIN-NOTE-DELETE-ALL, OPERATOR-ADMIN
 *
 *
 *  TRAFERIMENTI DOMINI:
 *      READ: DOMAIN-TRANSFER-READ, USER
 *      SEARCH: DOMAIN-TRANSFER-SEARCH, TECH- ADMIN
 */

// Ruoli provenienti dal Keycloak =>  keycloakUser.tokenParsed.realm_access.roles + keycloak.tokenParsed.resource_access...roles
type Roles =
  | 'OPERATOR-ADMIN'
  | 'OPERATOR'
  | 'TECH-ADMIN'
  | 'TECH'
  | 'USER'
  | 'LEGAL'
  | 'AUTHINFO-VIEWER'
  | 'DOMAIN-READ'
  | 'DOMAIN-HISTORY'
  | 'DOMAIN-SEARCH'
  | 'DOMAIN-EVENT-READ'
  | 'CONTACT-READ'
  | 'CONTACT-HISTORY'
  | 'CONTACT-SEARCH'
  | 'CONTACT-REGISTRY-UPDATE';

export const rolePermissions: Record<Roles, DefinePermissions> = {
  'TECH-ADMIN'(user, { can }) {
    can('manage', 'Domain');
    can('manage', 'Contact');
    can('manage', 'Note');
    can('manage', 'Registrar');
    can('manage', 'Event');
    can('manage', 'Note');
    can('read', 'Authinfo');
    // can("manage", 'all')
  },

  'OPERATOR-ADMIN'(user: Keycloak, { can }) {
    can('manage', 'Domain');
    can('manage', 'Contact');
    can('manage', 'Note');
    can('manage', 'Registrar');
    can('manage', 'Event');
    can('manage', 'Note');
  },

  'AUTHINFO-VIEWER'(user: Keycloak, { can }) {
    can('read', 'Authinfo');
  },

  OPERATOR(user, { can }) {
    can('read', 'Domain');
    can('search', 'Domain');
    can('history', 'Domain');
    can('search', 'Contact');
    can('read', 'Contact');
    can('history', 'Contact');
    can('registry-update', 'Contact');
    can('read', 'Registrar');
    can('create', 'Note');
    can('update', 'Note');
    can('delete', 'Note');
  },

  LEGAL(user, { can }) {
    can('read', 'Domain');
    can('read', 'Contact');
    can('search', 'Domain');
    can('search', 'Contact');
    can('history', 'Domain');
    can('history', 'Contact');
    can('read', 'Registrar');
    can('create', 'Note');
    can('update', 'Note');
    can('delete', 'Note');
  },

  TECH(user: Keycloak, { can }) {
    can('search', 'Domain');
    can('search', 'Contact');
    can('history', 'Domain');
    can('history', 'Contact');
    // can('read', 'Registrar');
  },

  USER(user: Keycloak, { can }) {
    can('read', 'Domain');
    can('read', 'Contact');
    can('read', 'Registrar');
    can('read', 'Event');
    can('read', 'Note');
  },

  'DOMAIN-EVENT-READ'(user: Keycloak, { can }) {
    can('read', 'Event');
  },

  'DOMAIN-HISTORY'(user: Keycloak, { can }) {
    can('history', 'Domain');
  },

  'DOMAIN-SEARCH'(user: Keycloak, { can }) {
    can('search', 'Domain');
  },

  'DOMAIN-READ'(user, { can }) {
    can('read', 'Domain');
  },

  'CONTACT-HISTORY'(user: Keycloak, { can }) {
    can('history', 'Contact');
  },

  'CONTACT-READ'(user: Keycloak, { can }) {
    can('read', 'Contact');
  },

  'CONTACT-SEARCH'(user: Keycloak, { can }) {
    can('search', 'Contact');
  },

  'CONTACT-REGISTRY-UPDATE'(user: Keycloak, { can }) {
    can('registry-update', 'Contact');
  },
};

/**
 * Definisce le Ablity per l'utente speficicato nel token Keycloak passato.
 * Dal token vegono prelevati tutti i ruoli presenti in "realm_access" (intesi come  profili) e tutti i ruoli
 * trovati in "resource_access".[application] (dove application identifica l'applicazione di provenienza, ad esempio
 * "domains-service", "customers-service", ....) .
 *
 * Una volta recuperati i ruoli vengono trasformati in upperCase().
 *
 * @param token (token keycloak)
 */
export function defineAbilityFor(token: Keycloak): AppAbility {
  const builder = new AbilityBuilder<AppAbility>();
  const { tokenParsed } = token;
  if (tokenParsed) {
    const roles = getRealmRoles(tokenParsed).concat(
      getResourceAccessRoles(tokenParsed),
    );
    // console.log("roles ", roles)
    roles.map(role => {
      const localRole = role.toUpperCase();
      if (typeof rolePermissions[localRole] === 'function') {
        rolePermissions[localRole](token, builder);
      } else {
        // throw new Error(`Trying to use unknown role "${JSON.stringify(keycloakUser.tokenParsed)}"`);
        console.debug(
          `Trying to use unknown role "${localRole}" => "${JSON.stringify(
            token.tokenParsed,
          )}"`,
        );
      }
    });
  }
  return builder.build();
}

/***
 * Ritorna un array di ruoli presenti in keycloak.tokenParsed.realm_access
 * @param tokenParsed
 */
export function getRealmRoles(tokenParsed: KeycloakTokenParsed): string[] {
  return tokenParsed &&
    tokenParsed.realm_access &&
    tokenParsed.realm_access.roles &&
    tokenParsed.realm_access.roles.length > 0
    ? tokenParsed.realm_access.roles
    : [];
}

/***
 * Ritorna un array di TUTTI i ruoli presenti in keycloak.tokenParsed.resource_access.
 * Si intende per questo che ogni ruolo sia univoco nel contensto di keycloak
 * @param tokenParsed
 */
export function getResourceAccessRoles(
  tokenParsed: KeycloakTokenParsed,
): string[] {
  const resourceAccess =
    tokenParsed && tokenParsed.resource_access
      ? tokenParsed.resource_access
      : [];
  return reduceRight(
    map(resourceAccess, 'roles'),
    (flattened, other) => {
      return flattened.concat(other as any);
    },
    [],
  );
}

/*** TOKEN DI ESEMPIO - qui si può vedere da dove si prelevano i ruoli   "resource_access",   "realm_access".


 {
  "exp": 1596705428,
  "iat": 1596705128,
  "jti": "76a0e555-5618-42c6-8078-56fe2944ce0f",
  "iss": "http://auth.devel.nic.it/auth/realms/registry",
  "aud": [
    "message-template-service",
    "domains-service",
    "accreditation-service",
    "account"
  ],
  "sub": "f:33c1c362-b1e2-42ff-9d7a-3ff740119da8:2",
  "typ": "Bearer",
  "azp": "auth-form",
  "session_state": "93fc0e85-7851-4779-9f1a-7da65dcc4947",
  "acr": "1",
  "realm_access": {
    "roles": [
      "tech",
      "domain-verify-operator",
      "offline_access",
      "uma_authorization",
      "user"
    ]
  },
  "resource_access": {
    "message-template-service": {
      "roles": [
        "admin"
      ]
    },
    "domains-service": {
      "roles": [
        "domain-read",
        "contact-read"
      ]
    },
    "account": {
      "roles": [
        "manage-account",
        "manage-account-links",
        "view-profile"
      ]
    }
  },
  "scope": "registry",
  "registry": true,
  "name": "Lorenzo Luconi Trombacchi",
  "preferred_username": "lorenzo.luconi",
  "userId": 2,
  "email": "lorenzo.luconi@test.nic.it"
}
 **/
