import 'moment/locale/it';
import { parse, ParsedQuery } from 'query-string';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { IRegistrar } from '../components/Registrar/IRegistrar';
import * as momentTimezone from 'moment-timezone';
import { axiosApiInstance } from '../const/axiosInstances';
import _ from 'lodash';
import Keycloak from 'keycloak-js';

/***
 *
 * @param url
 * @param page
 * @param size
 */
export function setPaginationParams(url: string, page: any, size: any): string {
  // console.log('setPaginationParams URL ', url);
  // Si aggiunge http per far credere a URL che quella passata sia una url, ma prima di ritornarla si fa ridiventare relativa
  const parsedUrl = new URL('http://' + url);
  parsedUrl.searchParams.set('size', size.toString());
  if (page !== undefined) {
    parsedUrl.searchParams.set('page', page.toString());
  }
  return '/' + parsedUrl.toString().replace('http://', '');
}

// function remove_first_occurrence(str: string, searchstr: string):string{
//   const index = str.indexOf(searchstr);
//   if (index === -1) {
//     return str;
//   }
//   return str.slice(0, index) + str.slice(index + searchstr.length);
// }

// funzione che permette di relativizzare l'indirizzo solitamente proveniente da risorse Hateos
// Obbligatorio prima essersi accertati di aver configurato le variabili di ambiente in .env
// export function toRelative(url: string): string {
//   const REACT_APP_API_PREFIX = process.env.REACT_APP_API_PREFIX || '/api';
//   const index = url.indexOf(REACT_APP_API_PREFIX);
//   if (index === -1) {
//     return url;
//   }
//   return url.slice(index + REACT_APP_API_PREFIX.length);
// }

// Ritorna true se nella querySearch è presente almeno uno dei requiredParams

/***
 *
 * @param params
 * @param requiredParams
 */
export const paramsValidator = (
  params: ParsedQuery,
  requiredParams: string[],
): boolean => {
  let allow = false;
  // console.log('params ', params);
  requiredParams.forEach((requiredParam: string) => {
    if (_.has(params, requiredParam)) {
      allow = true;
    }
  });

  return allow;
};

/***
 *
 * @param callback
 * @param url
 * @param keycloak
 */
export const axiosFetch = (callback: any, url: string, keycloak: Keycloak) => {
  return axiosApiInstance
    .get(url, { headers: { Authorization: 'Bearer ' + keycloak.token } })
    .then((res: any) => {
      callback(res);
    });
};

/***
 *
 * @param callback
 * @param url
 * @param keycloak
 */
export const axiosFetchCustomers = (
  callback: any,
  url: string,
  keycloak: Keycloak,
) => {
  return axiosApiInstance
    .get(url, { headers: { Authorization: 'Bearer ' + keycloak.token } })
    .then((res: any) => {
      callback(res);
    });
};

/***
 * Funzione wrapper per Moment che incorpora il valore TZ corretto
 * @param props
 */
export const moment = (props: any) => {
  momentTimezone.tz.setDefault('Europe/Rome');
  return momentTimezone(props);
};

// Restituisce il valore in moment del contenuto del campo passato input. Da usare nelle Form
export function multipleMomentValues(
  selectedOptions: ParsedQuery<React.ReactText> | undefined,
  index: string,
) {
  const result: any[] = [];
  if (selectedOptions != null && selectedOptions[index]) {
    for (const opt of selectedOptions[index] as any) {
      result.push(moment(opt));
    }
  }
  return result;
}

// /**
//  * Deep diff between two object, using lodash
//  * @param  {Object} object Object compared
//  * @param  {Object} base   Object to compare with
//  * @return {Object}        Return a new object who represent the diff
//  */
// export function difference(object: any, base: any) {
//     return transform(object, (result, value, key) => {
//         if (!isEqual(value, base[key])) {
//             result[key] = isObject(value) && isObject(base[key]) ? difference(value, base[key]) : value;
//         }
//     });
// }

/**
 * Dice se il gli oggetti passati sono uguali come contenuti
 * @param first - oggetto 1
 * @param second - oggetto «“!"2
 */
export function objectesAreEquals(first: any, second: any) {
  let ret = true;
  _.forEach(second, (value, key) => {
    if (first[key] !== value) {
      ret = false;
    }
  });
  return ret;
}

// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQueryTwo() {
  // console.log(" useLocation().search", useLocation().search);
  return new URLSearchParams(useLocation().search);
}

/***
 * Un hook personalizzato che si basa su useLocation per analizzare la stringa di query per te e ritorna un oggetto
 * query-string parsato.
 */
export function useQuery() {
  return parse(useLocation().search, { parseNumbers: false });
}

/*** Imposta il titolo della finestra visualizzata
 *
 * @param props
 * @constructor
 */
export function documentTitle(title: string, noPrefix?: boolean) {
  const prefix = 'MIDA - ';
  document.title = noPrefix ? title : prefix.concat(title);
}

export const capitalizeFirstLetter = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

/**
 * Stringa utile per ottenere il valore dello hue .
 * La funzione ritorna un colore in formato HSL (hue, saturation, lightness)
 * RIF: https://medium.com/@pppped/compute-an-arbitrary-color-for-user-avatar-starting-from-his-username-with-javascript-cd0675943b66
 * @param str
 * @param s - saturation : 0 .. 100
 * @param l - ligthness : 0 .. 100
 */
export function stringToHslColor(str: string, s: number, l: number) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    // tslint:disable-next-line:no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

/**
 * Dice se il dominio appertiene al periodo sincrono
 * @param registrar
 */
export function isDomainSync(registrar: IRegistrar) {
  return (
    registrar.name.toUpperCase().indexOf('-MNT') !== -1 ||
    registrar.name.toUpperCase().indexOf('-ENT') !== -1
  );
}

/**
 * Ritorna il primo carattere in miauscolo + il primo carattere dopo / e tutti i primi caratteri in Maiuscolo
 * Es: inactive / toBeReassigned => I/TBR
 */
export function calcAvatarString(str: string) {
  if (str.length > 3) {
    return (
      str[0] +
      '/' +
      str.substr(str.indexOf('/') + 2, 1) +
      str.replace(/[a-z\/ ]/g, '')
    ).toUpperCase();
  } else {
    return str.toUpperCase();
  }
}

export function getInitials(name: string) {
  const aa = name.match(/(\b\S)?/g);
  if (aa) {
    return aa.join('').toUpperCase();
  }

  return '';
}

/**
 * Conta le occorrenze si char_to_Count dentro un testo o insieme di line
 * @param str
 * @param char_to_count
 */
export function find_occurences(str: string, char_to_count: string) {
  return str.split(char_to_count).length - 1;
}

/***
 *
 * Funzione che permette di salvare nel filesystem un blob proveniente da una GET
 *
 * @param blobPart
 * @param filename
 * @param format
 */
export function saveBlob(
  blobPart: BlobPart,
  filename: string = 'download.csv',
  extension: string = 'csv',
): void {
  let data;
  data =
    extension === 'csv'
      ? new Blob([blobPart], { type: 'text/csv' })
      : new Blob([JSON.stringify(blobPart)], { type: 'application/json' });
  const fileUrl = window.URL.createObjectURL(data);
  const tempLink = document.createElement('a');
  tempLink.href = fileUrl;
  tempLink.setAttribute('download', filename);
  tempLink.click();
}
