import * as React from 'react';
import { Ability } from '@casl/ability';
import { AppAbility, defineAbilityFor } from './config';
import { useAuth } from '@nic/kc-auth';

export interface IAbilityContext {
  ability: AppAbility;
}

export const AbilityContext = React.createContext<IAbilityContext>({
  ability: new Ability(),
});

const AbilityProvider: React.FC<{}> = props => {
  // Il token keycloack potrebbe essere passato come parametro alla Componente "AbilityProvider" o
  // essere recuperato attraverso un hooks dal contesto come in questo caso
  const {keycloak} = useAuth();

  const [ability, setAbility] = React.useState<AppAbility>(new Ability([]));

  React.useEffect(() => {
    if (keycloak && keycloak.tokenParsed) {
      console.debug('keycloak.tokenParsed ', keycloak.tokenParsed);
      setAbility(defineAbilityFor(keycloak));
    }
  }, [keycloak]);

  return (
    <AbilityContext.Provider value={{ ability }}>
      {props.children}
    </AbilityContext.Provider>
  );
};

const AbilityConsumer = AbilityContext.Consumer;
export { AbilityProvider, AbilityConsumer };
